import React, { useContext } from "react";
import { Location } from "@gatsbyjs/reach-router";
import styled from "styled-components";
import { Table } from "antd";
import { Context } from "../../../components/Layout/index";
import Note from "../../CmsComponents/Note";
import "antd/lib/table/style/index.css";

import "../../../themes/css/index.css";

// import Info from "../../../svg/info.svg";
// import InfoDark from "../../../svg/info-dark.svg";

const dataSource = [
  {
    key: "1",
    status: "SCHEDULED",
    type: "Temporary",
    description:
      "The message is scheduled. Delivery has not yet been initiated."
  },
  {
    key: "2",
    status: "ENROUTE",
    type: "Temporary",
    description:
      "The message is in enroute state, it might be in retry or dispatched to a mobile network for delivery to the mobile."
  },
  {
    key: "3",
    status: "DELIVERED",
    type: "Final",
    description: "The message has been delivered to the destination."
  },
  {
    key: "4",
    status: "UNDELIVERABLE",
    type: "Final",
    description:
      "The message has encountered a delivery error and is deemed permanently undeliverable. The recipient number is in an unrecognized format or the destination is not managed."
  },
  {
    key: "5",
    status: "UNDELIVERED",
    type: "Final",
    description:
      "Reception error, the message has failed to be delivered within its validity period and/or retry period."
  }
];

const columns = [
  {
    title: "Status",
    dataIndex: "status",
    key: "status"
  },
  { title: "Type", dataIndex: "type", key: "type" },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    render: (html) => {
      return <Container dangerouslySetInnerHTML={{ __html: html }} />;
    }
  }
];

const StatusTable = ({ margin, commonsNote }) => {
  const { width } = useContext(Context) || {};

  return (
    <MaxSize>
      <Wrapper width={width} style={{ marginTop: width > 1360 && margin }}>
        <p>The status.value field list is described below:</p>
        <Table columns={columns} dataSource={dataSource} pagination={false} />
        {/* <StaticNote>
          <div>{currentTheme === "light" ? <Info /> : <InfoDark />}</div>
          <p>
            UNDELIVERABLE could also be triggered by an Anti spam error.{" "}
            <a
              href="https://support.smsmode.com/en/base-de-connaissance/anti-spam-error-sending"
              target="_blank"
              rel="noopener noreferrer"
            >
              Why do I get an "Anti-spam error"
            </a>
          </p>
        </StaticNote> */}
      </Wrapper>
      {commonsNote && (
        <Note
          style={{ maxWidth: "850px" }}
          anchor={"bottom_cms_table_consumption_statuses"}
        />
      )}
    </MaxSize>
  );
};


const Container = styled.div``;
const MaxSize = styled.div`
  max-width: 800px;
`;

const Wrapper = styled.div`
  margin: 60px 0px 20px;
  width: 100%;

  & > p {
    font-size: 14px;
    padding-bottom: 20px;
    line-height: 25px;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border-bottom: ${({ theme }) => theme.border} !important;
  }

  .ant-table-cell,
  .ant-table {
    color: ${({ theme }) => theme.text} !important;
  }

  .ant-table-content {
    overflow-x: auto !important;
  }

  @media (max-width: 1360px) {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
      font-size: 10px;
    }
  }
`;

//eslint-disable-next-line
export default (props) => (
  <Location>
    {(locationProps) => <StatusTable {...locationProps} {...props} />}
  </Location>
);
