import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { Context } from "../../Layout/index";
import Info from "../../../svg/info.svg";
import InfoDark from "../../../svg/info-dark.svg";

const Note = ({ apiName, anchor, ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        nodes {
          frontmatter {
            anchor
            apiName
            note
          }
        }
      }
    }
  `);

  const { currentTheme, setAnimate } = useContext(Context) || {};

  const note = data?.allMarkdownRemark?.nodes?.filter(
    (item) => item?.frontmatter?.anchor === anchor
  );

  useEffect(() => {
    const button = document.getElementById("button");
    if (button) {
      button.onclick = () => {
        setAnimate(true);
        setTimeout(() => {
          setAnimate(false);
        }, 1500);
      };
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {note?.map?.((item) =>
            (item?.frontmatter?.note
            && item?.frontmatter?.apiName?.includes?.(apiName)) ? (
            <Wrapper
              {...props}
              id={anchor}
              key={`cms-note-${item.frontmatter.apiName}-${item.frontmatter.anchor}`}
              data-testid="Note-Wrapper"
            >
              <div>{currentTheme === "light" ? <Info /> : <InfoDark />}</div>
              <p
                id="text"
                dangerouslySetInnerHTML={{
                  __html: item?.frontmatter?.note
                }}
              />
            </Wrapper>
          ) : null
        )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.second};
  border-radius: 5px;
  padding: 15px;
  margin-top: 30px;
  & > div {
    margin-right: 5px;
    margin-top: 5px;
  }
  & > p {
    font-size: 14px;
    line-height: 25px;
    & > #button {
      cursor: pointer;
    }
  }
  & > p > span {
    font-weight: 900;
  }
  & > p > .italic {
    font-style: italic;
    font-weight: normal;
  }
  & > p > a {
    color: #52b474;
  }
`;

export default Note;
