exports.windev = `/**
*    Function parameters:
*
*    - accessToken (required)
*    - message (required)
*    - recipients (required): Receivers separated by a comma
*    - transmitter (optional): Allows to deal with the sms sender
*    - optionStop (optional): Deal with the STOP sms when marketing send (cf. API HTTP documentation)
*    - batchFilePath (required for batch mode): The path of CSV file for sms in Batch Mode
*/
 
ExampleClientHttpApi is a Class
 
 URL is a string = "https://api.smsmode.com/http/1.6/"
 PATH_SEND_SMS is a string = "sendSMS.do".
 PATH_SEND_SMS_BATCH is a string = "sendSMSBatch.do"
FIN
 
PROCEDURE Builder()
 
PROCEDURE Destructor()
 
 
PROCEDURE sendSmsGet(accessToken is a string, message is a string, recipients is a string, sender is a string, optionStop is a string)
 
getField is a chain
getField = "?accessToken=" + accessToken + "&message=" + message + "&number=" + recipients + "&sender=" + transmitter + "&stop=" + optionStop
 
IF HTTQuery(URL + PATH_SEND_SMS + getField) = True THEN
 REFER HTMLVersTexte(HTTPDataResult(httpResult))
SINON
 REFER ErrorInfo()
FIN
 
 
PROCEDURE sendSmsPost(accessToken is a string, message is a string, recipient is a string, sender is a string, optionStop is a string)
 
postField is a channel
postField = "accessToken=" + accessToken + "&message=" + message + "&number=" + recipients + "&sender=" + transmitter + "&stop=" + optionStop
 
IF HTTQuery(URL + PATH_SEND_SMS, "", "", postField) = True THEN
 REFER HTMLVersTexte(HTTPDataResult(httpResult))
SINON
 REFER (ErrorInfo())
FIN
 
 
PROCEDURE sendSmsBatch(accessToken is a string, batchFilePath is a string, optionStop is a string)
 
fdFile is an integer
fdFile = fOuvre(batchFilePath)
IF fdFile = -1 THEN
 REFER ("The specified file does not exist")
FIN
contentFile is a channel
contentFile = fLit(fdFile,fSize(batchFilePath))
fFarm(fdFile)
 
 
header is a string = "multipart/form-data; boundary=" + boundary
 
postField is a channel
postField = "--" + boundary + RC +
Content-Disposition: form-data; name=""datafile""; filename=" + batchFilePath + """ + RC + RC + RC + 
contentFile + 
RC + "--" + boundary + "--"
 
IF HTTQuery(URL + PATH_SEND_SMS_BATCH + "?accessToken=" + accessToken +"&stop=" + optionStop, "", "", postField, header) = True THEN
 REFER HTMLVersTexte(HTTPDataResult(httpResult))
SINON
 REFER (ErrorInfo())
FIN`;
