import styled from "styled-components";

export const Title = styled.h1`
  width: 100%;
  max-width: 820px;
  line-height: 40px;
  font-size: 35px;
  font-weight: 500;
  color: #52b474;
  margin-top: 10px;
  color: ${({ theme }) => theme.text};
  @media (max-width: 870px) {
    font-size: 18px;
    line-height: 25px;
  }
  @media (max-width: 550px) {
    margin-bottom: 20px;
  }
`;
