exports.cpp = `#include <iostream>
#include <curl/curl.h>
#include <string.h>
#include <sys/stat.h>
#include <unistd.h>
 
/**
*    Function parameters:
*
*    - accessToken (required)
*    - message (required)
*    - destinataires (required): Receivers separated by a comma
*    - emetteur (optional): Allows to deal with the sms sender
*    - optionStop (optional): Deal with the STOP sms when marketing send (cf. API HTTP documentation)
*    - batchFilePath (required for batch mode): The path of CSV file for sms in Batch Mode
*/
 
#define URL "https://api.smsmode.com/http/1.6/";
#define PATH_SEND_SMS "sendSMS.do";
#define PATH_SEND_SMS_BATCH "sendSMSBatch.do";
#define ERROR_API "Error during API call";
#define ERROR_FILE "The specified file does not exist";
 
using namespace std;
 
static size_t WriteCallback(char *contents, size_t size, size_t nmemb, void *userp)
{
 ((string*)userp)->append((char*)contents, size * nmemb);
 return size * nmemb;
}
 
class ExempleClientHttpAPI {
 
 public:
 
 // send SMS with GET method
 string sendSmsGet(string accessToken, string message, string destinataires, string emetteur, string optionStop) {
 
     string finalUrl = URL;
     finalUrl += PATH_SEND_SMS;
     finalUrl += "?accessToken=" + accessToken +
     "&message=" + curl_escape(message.c_str(), message.length()) +
     "&numero=" + destinataires +
     "&emetteur=" + emetteur +
     "&stop=" + optionStop;
 
     string readBuffer;
     CURL *curl;
     CURLcode res;
     curl = curl_easy_init();
     if(curl) {
         curl_easy_setopt(curl, CURLOPT_URL, finalUrl.c_str());
         curl_easy_setopt(curl, CURLOPT_WRITEFUNCTION, WriteCallback);
         curl_easy_setopt(curl, CURLOPT_WRITEDATA, &readBuffer);
         res = curl_easy_perform(curl);
         if(res != CURLE_OK)
         {
             fprintf(stderr, "curl_easy_perform() failed: %s\n", curl_easy_strerror(res));
             return ERROR_API;
         }
         curl_easy_cleanup(curl);
     }
     return readBuffer;
 }
 
 // send SMS with POST method
 string sendSmsPost(string accessToken, string message, string destinataires, string emetteur, string optionStop) {
 
     string finalUrl = URL;
     finalUrl += PATH_SEND_SMS;
     string postField = "accessToken=" + accessToken + "&message=" + message +
         "&numero=" + destinataires + "&emetteur=" + emetteur + "&stop=" + optionStop;
  
     string readBuffer;
     CURL *curl;
     CURLcode res;
     curl = curl_easy_init();
     if(curl) {
         curl_easy_setopt(curl, CURLOPT_URL, finalUrl.c_str());
         curl_easy_setopt(curl, CURLOPT_POSTFIELDS, postField.c_str());
         curl_easy_setopt(curl, CURLOPT_WRITEFUNCTION, WriteCallback);
         curl_easy_setopt(curl, CURLOPT_WRITEDATA, &readBuffer);
         res = curl_easy_perform(curl);
         if(res != CURLE_OK)
         {
             fprintf(stderr, "curl_easy_perform() failed: %s\n", curl_easy_strerror(res));
             return ERROR_API;
         }
         curl_easy_cleanup(curl);
     }
     return readBuffer;
 }
 
 // send SMS with POST method (Batch)
 string sendSmsBatch(string accessToken, string batchFilePath, string optionStop)
 {
     if(access(batchFilePath.c_str(), F_OK) == -1)
         return ERROR_FILE; 
     string finalUrl = URL;
     finalUrl += PATH_SEND_SMS_BATCH;
     finalUrl += "?accessToken=" + accessToken + 
         "&stop=" + optionStop;
     struct curl_httppost *formpost=NULL;
     struct curl_httppost *lastptr=NULL;
     string readBuffer;
     CURL *curl;
     CURLcode res;
     curl = curl_easy_init();
     if(curl) {
         curl_formadd(&formpost,
             &lastptr,
             CURLFORM_COPYNAME, "sendfile",
             CURLFORM_FILE, batchFilePath.c_str(),
             CURLFORM_END);
 
         curl_formadd(&formpost,
             &lastptr,
             CURLFORM_COPYNAME, "filename",
             CURLFORM_COPYCONTENTS, batchFilePath.c_str(),
             CURLFORM_END);
 
         curl_easy_setopt(curl, CURLOPT_URL, finalUrl.c_str());
         curl_easy_setopt(curl, CURLOPT_WRITEFUNCTION, WriteCallback);
         curl_easy_setopt(curl, CURLOPT_WRITEDATA, &readBuffer);
         curl_easy_setopt(curl, CURLOPT_HTTPPOST, formpost);
         res = curl_easy_perform(curl);
         if(res != CURLE_OK)
         {
             fprintf(stderr, "curl_easy_perform() failed: %s\n", curl_easy_strerror(res));
             return ERROR_API;
         }
     }
     curl_easy_cleanup(curl);
     return readBuffer;
 }
};`;
