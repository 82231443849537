import React from "react";
import styled from "styled-components";
import { string } from "prop-types";
import Moon from "../../svg/moon.svg";
import Sun from "../../svg/sun.svg";

const ToggleTheme = ({ className, ...props }) => {
  return (
    <Wrapper>
      <Moon />
      <Container {...props} className={className} type="checkbox" />
      <Sun />
    </Wrapper>
  );
};

ToggleTheme.propTypes = {
  className: string
};

ToggleTheme.defaultProps = {
  className: ""
};

const Wrapper = styled.div`
  // @media (min-width: 900px) {
  //   margin-left: 50px;
  // }
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100px;
  & > svg {
    height: 20px;
    width: 20px;
  }
`;

const Container = styled.input`
  position: relative;
  height: 20px;
  width: 40px;
  -webkit-appearance: none;
  background:#52b474;
  
  /* background: ${({ theme }) => theme.body}; */
  outline: none;
  border-radius: 12px;
  transition: 0.5s;
  border: 1px solid ${({ theme }) => theme.second};
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 20px;
    border-color: ${({ theme }) => theme.body};
    top: 1px;
    left: 1px;
    background: ${({ theme }) => theme.second};
    transition: 0.5s;
    text-align: center;
    line-height: 14px;
  }

  &:checked::before {
    left: 21px;
  }
`;

export default ToggleTheme;
