exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alert-http-api-js": () => import("./../../../src/pages/alert-http-api.js" /* webpackChunkName: "component---src-pages-alert-http-api-js" */),
  "component---src-pages-http-api-alert-js": () => import("./../../../src/pages/http-api/alert.js" /* webpackChunkName: "component---src-pages-http-api-alert-js" */),
  "component---src-pages-http-api-reference-js": () => import("./../../../src/pages/http-api/reference.js" /* webpackChunkName: "component---src-pages-http-api-reference-js" */),
  "component---src-pages-http-api-snippets-js": () => import("./../../../src/pages/http-api/snippets.js" /* webpackChunkName: "component---src-pages-http-api-snippets-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mail-2-sms-js": () => import("./../../../src/pages/mail2sms.js" /* webpackChunkName: "component---src-pages-mail-2-sms-js" */),
  "component---src-pages-well-known-pgp-acs-js": () => import("./../../../src/pages/.well-known/pgp.acs.js" /* webpackChunkName: "component---src-pages-well-known-pgp-acs-js" */),
  "component---src-pages-well-known-security-txt-js": () => import("./../../../src/pages/.well-known/security.txt.js" /* webpackChunkName: "component---src-pages-well-known-security-txt-js" */),
  "component---src-templates-page-template-cms-js": () => import("./../../../src/templates/page-templateCms.js" /* webpackChunkName: "component---src-templates-page-template-cms-js" */),
  "component---src-templates-page-template-errors-js": () => import("./../../../src/templates/page-templateErrors.js" /* webpackChunkName: "component---src-templates-page-template-errors-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-page-template-redirect-js": () => import("./../../../src/templates/page-templateRedirect.js" /* webpackChunkName: "component---src-templates-page-template-redirect-js" */)
}

