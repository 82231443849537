import React from "react";
import styled from "styled-components";

const Round = ({ className, side = "left", position = "top" }) => {
  return (
    <Svg
      className={className}
      side={side}
      position={position}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path d="M0 10V0C0 5 5 10 10 10H0Z" fill="#FF0000" />
    </Svg>
  );
};

export default Round;

const Svg = styled.svg`
  transform: scale(
    ${({ side }) => (side === "right" ? "-1" : "1")},
    ${({ position }) => (position === "bottom" ? "-1" : "1")}
  );
`;

const Path = styled.path`
  fill: ${({ theme }) => theme.second};
`;
