import React, { useContext } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Logo from "../../svg/smsmode-icon.svg";
import { Location } from "@gatsbyjs/reach-router";

import ToggleTheme from "../ToggleTheme";
import { Context } from "../Layout/index";

const Header = ({ toggleTheme, width, height, location }) => {
  const { currentTheme } = useContext(Context) || {};

  return (
    <Container>
      {/* <div> */}
      <LinkTitle
        to="/"
        onClick={() =>
          document
            .getElementById("layout_content")
            .scrollTo({ top: 0, behavior: "smooth" })
        }
      >
        <Logo />
        {width > 1100 && <TitleWrapper>{"for Developers"}</TitleWrapper>}
      </LinkTitle>
      <Content>
        <ToggleTheme
          onChange={() => toggleTheme()}
          checked={currentTheme === "light" ? true : false}
          currentTheme={currentTheme}
          width={width}
        />
      </Content>

      {width > 1100 && (
        <LinksWrapper height={height}>
          <a
            href="https://ticket.smsmode.com/tickets-view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Support
          </a>
          <a
            href="https://ui.smsmode.com/login"
            target="_blank"
            rel="noopener noreferrer"
          >
            Sign in
          </a>
        </LinksWrapper>
      )}
    </Container>
  );
};

const TitleWrapper = styled.h1`
  font-size: 18px;
  color: ${({ theme }) => theme.text};
`;

const LinkTitle = styled((props) => <Link {...props} />)`
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 20px;

  & > svg {
    height: 35px;
  }
`;

const LinksWrapper = styled.div`
  display: ${({ height }) => (height < 950 ? "none" : "flex")};
  align-items: center;
  font-weight: 400;
  & > * {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${({ theme }) => theme.text};
  }
  & > a:last-child {
    background-color: #52b474;
    color: #fff;
    height: 35px;
    padding: 0 15px;
    border-radius: 5px;
    transition: ease 0.275s;
    margin: 0 10px 0 15px;
  }
  & > a:last-child:hover {
    transform: translateY(-0.1em);
    box-shadow: 0 5px 20px rgb(0 0 0 / 10%);
  }
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  gap: 20px;
`;

const Container = styled.header`
  grid-area: header;
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.second};
  display: flex;
  align-items: center;
  gap: 50px;
  padding: 10px 20px;
  box-shadow: 0 5px 20px rgb(0 0 0 / 10%);
  z-index: 101;
`;

//eslint-disable-next-line
export default (props) => (
  <Location>
    {(locationProps) => <Header {...locationProps} {...props} />}
  </Location>
);
