import React from "react";
import Axios from "axios";
import Qs from "qs";

import StatusTable from "../components/PageElements/Elements/StatusTable";
import MsgCountTable from "../components/PageElements/Elements/MsgCountTable";

import BackgroundCommonsLight from "../svg/commons_light.svg";
import BackgroundCommonsDark from "../svg/commons_dark.svg";

import BackgroundSmsLight from "../svg/sms_light.svg";
import BackgroundSmsDark from "../svg/sms_dark.svg";

import ApiCardCommonsLight from "../svg/apicard_commons_light.svg";
import ApiCardCommonsDark from "../svg/apicard_commons_dark.svg";

import ApiCardSmsLight from "../svg/apicard_sms_light.svg";
import ApiCardSmsDark from "../svg/apicard_sms_dark.svg";

import ApiCardMail2SmsLight from "../svg/api-mail2sms-light-mode.svg";
import ApiCardMail2SmsDark from "../svg/api-mail2sms-dark-mode.svg";

import ApiCardHttpLight from "../svg/api-http-light-mode.svg";
import ApiCardHttpDark from "../svg/api-http-dark-mode.svg";

export const getName = (name) =>
  name
    ? name
        .toLocaleLowerCase()
        .replace(/[{}]|(api)|(rest)/g, "")
        .trim()
    : "";

export const formatPath = (path) =>
  path && path.toLocaleLowerCase().replace(/[{}]/g, "");

export const getVersion = (path) =>
  (path &&
    path.additionalData?.version.split(".")[0].toLocaleLowerCase().trim()) ||
  "";

export const scrollToSection = (id, yOffset) => {
  console.log(id)
  const element = document.getElementById(id);
  if (element) {
    // element.scrollIntoView({ behavior: 'smooth' });
    window.scrollTo({ top: element.offsetTop + yOffset })
  }
};

export const getOpForPath = (path, sections) => {
  return Object.keys(
    sections.find((section) => section.path === path)?.additionalData || {}
  ).filter((op) => op !== "tags" && op !== "summary");
};

export const getAppJson = (res) => {
  if (res && parseInt(res) >= 400) return "application/problem+json";
  return "application/json";
};

export const getErrorTextStatus = (code) => {
  switch (parseInt(code)) {
    case 204:
      return "204 (No content)";
    case 400:
      return "400 (Bad Request)";
    case 401:
      return "401 (Unauthorized)";
    case 403:
      return "403 (Forbidden)";
    case 404:
      return "404 (Not Found)";
    case 405:
      return "405 (Method Not Allowed)";
    case 406:
      return "406 (Not Acceptable)";
    case 409:
      return "409	(Conflict)";
    case 415:
      return "415 (Unsupported Media Type)";
    case 500:
      return "500 (Internal Server Error)";
    default:
      return "Unknown error";
  }
};

export const sortErrorCode = (data) => {
  return data
    ?.reduce(
      (acc, current) => [
        ...acc,
        ...current.fields.reduce(
          (acc2, field) => [
            ...acc2,
            ...field.errorTypes.map((errorType) => ({
              ...errorType,
              fieldName: field.name
            }))
          ],
          []
        )
      ],
      []
    )
    .sort((a, b) => a.errorCode.localeCompare(b.errorCode));
};

export const replaceFieldName = (str, fieldName) => {
  return str?.replace("{fieldName}", fieldName);
};

export const getOperationColor = (op, path) => {
  if (path === "/callback") {
    return "#564592";
  }
  switch (op) {
    case "get":
      return "#52b474";
    case "post":
      return "#fec656";
    case "patch":
      return "#1D70A2";
    case "delete":
      return "#E63B2E";
    default:
      return "Unknown operation";
  }
};

export const handleTypeDisplay = (type, path) => {
  if (path === "/callback") {
    return "HOOK";
  } else return type.toUpperCase();
};

export const tryItOutPatch = async (
  getQueryParams,
  url,
  exampleData,
  operation,
  handlePathParams,
  getPathParams,
  body
) => {
  try {
    return await Axios.create({
      headers: {
        "X-Api-Key": sessionStorage.getItem("X-Api-Key"),
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    }).request({
      url: `${handlePathParams(
        url, // URL
        getPathParams(exampleData?.additionalData[operation]?.parameters)
      )}`,
      method: "patch",
      params: getQueryParams(
        exampleData?.additionalData[operation]?.parameters
      ),
      paramsSerializer: (params) => {
        return Qs.stringify(params);
      },
      data: body
    });
  } catch (error) {
    return error.response;
  }
};

export const tryItOutPost = async (
  getQueryParams,
  url,
  exampleData,
  operation,
  handlePathParams,
  getPathParams,
  body
) => {
  try {
    return await Axios.create({
      headers: {
        "X-Api-Key": sessionStorage.getItem("X-Api-Key"),
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    }).request({
      url: `${handlePathParams(
        url, // URL
        getPathParams(exampleData?.additionalData[operation]?.parameters)
      )}`,
      method: "post",
      data: body
    });
  } catch (error) {
    return error.response;
  }
};

export const tryItOutGet = async (
  getQueryParams,
  url,
  exampleData,
  operation,
  handlePathParams,
  getPathParams
) => {
  try {
    return await Axios.create({
      headers: {
        "X-Api-Key": sessionStorage.getItem("X-Api-Key"),
        Accept: "application/json"
      }
    }).request({
      url: `${handlePathParams(
        url, // URL
        getPathParams(exampleData?.additionalData[operation]?.parameters)
      )}`,
      method: "get",
      params: getQueryParams(
        exampleData?.additionalData[operation]?.parameters
      ),
      paramsSerializer: (params) => {
        return Qs.stringify(params);
      }
    });
  } catch (error) {
    return error.response;
  }
};

export const tryItOutDelete = async (
  getQueryParams,
  url,
  exampleData,
  operation,
  handlePathParams,
  getPathParams
) => {
  //  <----

  try {
    return await Axios.create({
      headers: {
        "X-Api-Key": sessionStorage.getItem("X-Api-Key"),
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    }).request({
      url: `${handlePathParams(
        url, // URL
        getPathParams(exampleData?.additionalData[operation]?.parameters)
      )}`,
      method: "delete",
      params: getQueryParams(
        exampleData?.additionalData[operation]?.parameters
      ),
      paramsSerializer: (params) => {
        return Qs.stringify(params);
      }
    });
  } catch (error) {
    return error.response;
  }
};

export const getBackground = (theme, apiName) => {
  if (theme === "light" && apiName === "sms") return <BackgroundSmsLight />;
  else if (theme === "dark" && apiName === "sms") return <BackgroundSmsDark />;
  else if (theme === "light" && apiName === "commons")
    return <BackgroundCommonsLight />;
  else if (theme === "dark" && apiName === "commons")
    return <BackgroundCommonsDark />;
  else if (theme === "dark") return <BackgroundCommonsDark />;
  return <BackgroundCommonsLight />;
};

export const getApiCardImg = (theme, apiName) => {
  if (theme === "light" && apiName === "mail2sms")
    return <ApiCardMail2SmsLight />;
  if (theme === "dark" && apiName === "mail2sms")
    return <ApiCardMail2SmsDark />;
  if (theme === "light" && apiName === "http") return <ApiCardHttpLight />;
  if (theme === "dark" && apiName === "http") return <ApiCardHttpDark />;

  if (theme === "light" && apiName === "sms") return <ApiCardSmsLight />;
  else if (theme === "dark" && apiName === "sms") return <ApiCardSmsDark />;
  else if (theme === "light" && apiName === "commons")
    return <ApiCardCommonsLight />;
  else if (theme === "dark" && apiName === "commons")
    return <ApiCardCommonsDark />;
  else if (theme === "dark") return <ApiCardCommonsDark />;
  return <ApiCardCommonsLight />;
};

export const sortMethods = (data) => {
  const pattern = ["post", "get", "put", "patch", "delete"];
  return data.sort((a, b) => pattern.indexOf(a) - pattern.indexOf(b));
};

export const sizingContainer = (array) => {
  if (
    array.includes("from") ||
    array.includes("endDate") ||
    array.includes("sentDate")
  )
    return true;
  return false;
};

export const displayStatusTable = (op, path) => {
  if ((op === "get" && path === "/messages/{messageId}") ||
      (op === "get" && path === "/campaigns/{campaignId}") ||
      (op === "get" && path === "/campaigns/{campaignId}/messages/{messageId}")) {
    return <StatusTable />;
  } else if (op === "get" && path === "/consumptions/{consumptionId}") {
    return <StatusTable
        commonsNote={true}/>;
  }
};

export const displayMsgCountTable = (apiData, op, path) => {

  if (apiData?.name && apiData.name.includes("SMS") &&
      ((op === "post" && path === "/messages") ||
      (op === "post" && path === "/campaigns") ||
      (op === "post" && path === "/campaigns/{campaignId}/messages"))) {
      return <MsgCountTable />
  }
};
