exports.scala = `import scalaj.http._
import java.nio.file.{Files, Paths}
 
/**
*    Function parameters:
*
*    - accessToken (required)
*    - message (required)
*    - destinataires (required): Receivers separated by a comma
*    - emetteur (optional): Allows to deal with the sms sender
*    - optionStop (optional): Deal with the STOP sms when marketing send (cf. API HTTP documentation)
*    - batchFilePath (required for batch mode): The path of CSV file for sms in Batch Mode
*/
 
object ExempleClientHttpApi {
  
 var ERROR_FILE = "The specified file does not exist";
 var URL = "https://api.smsmode.com/http/1.6/";
 var PATH_SEND_SMS = "sendSMS.do";
 var PATH_SEND_SMS_BATCH = "sendSMSBatch.do";
 
 // send SMS with GET method
 def send_sms_get(accessToken:String, message:String, destinataires:String, emetteur:String, optionStop:String) : String = {
     var SendSMSUsingGET = Http(URL + PATH_SEND_SMS)
     .param("accessToken", accessToken)
     .param("message", message)
     .param("numero", destinataires)
     .param("emetteur", emetteur)
     .param("stop", optionStop)
     .charset("ISO-8859-15")
     .asString.body
  
     return SendSMSUsingGET;
 }
 
 // send SMS with POST method
 def send_sms_post(accessToken:String, message:String, destinataires:String, emetteur:String, optionStop:String) : String = {
     var SendSMSUsingPOST = Http(URL + PATH_SEND_SMS)
     .postForm(
         Seq(
             "accessToken" -> accessToken,
             "message" -> message,
             "numero" -> destinataires,
             "emetteur" -> emetteur,
             "stop" -> optionStop
         )
     )
     .charset("ISO-8859-15")
     .asString.body
 
     return SendSMSUsingPOST;
 }
 
 // send SMS with POST method (Batch)
 def send_sms_batch(accessToken:String, batchFilePath:String, optionStop:String) : String = {
 
     if (!Files.exists(Paths.get(batchFilePath))) {
         return ERROR_FILE;
     }
 
     var finalUrl : String = URL + PATH_SEND_SMS_BATCH + "?accessToken=" + accessToken + "&stop=" + optionStop;
     var SendSMSUsingPOSTBatch = Http(finalUrl)
     .postMulti(MultiPart("file", Paths.get(batchFilePath).getFileName().toString(), "text/csv", Files.readAllBytes(Paths.get(batchFilePath))))
     .asString.body
 
     return SendSMSUsingPOSTBatch;
 }
}`;
