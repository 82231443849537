import styled from "styled-components";

export const New = styled.p`
  background-color: #52b4746d;
  color: ${({ theme }) => theme.text};
  border-radius: 5px;
  font-weight: 300;
  font-size: 10px;
  padding: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  margin-left: 5px;
`;
