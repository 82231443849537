const phpSnippet = require("../config/snippets/php.js");
const javaSnippet = require("../config/snippets/java.js");
const nodeSnippet = require("../config/snippets/node.js");
const pythonSnippet = require("../config/snippets/python.js");
const csharpSnippet = require("../config/snippets/csharp.js");
const windevSnippet = require("../config/snippets/windev.js");
const rubySnippet = require("../config/snippets/ruby.js");
const scalaSnippet = require("../config/snippets/scala.js");
const cppSnippet = require("../config/snippets/cpp.js");

exports.transformSwaggerPaths = (paths, additionalData = {}) => {
  return Object.entries(paths).reduce(
    (acc, [key, value]) => [
      ...acc,
      {
        path: key,
        additionalData: {
          summary: "",
          get: null,
          post: null,
          put: null,
          patch: null,
          delete: null,
          ...value,
          ...additionalData
        }
      }
    ],
    []
  );
};

exports.getPathsForTags = (node) => {
  const tags = {};

  node &&
    node.paths
      .map((path) => ({
        ...path,
        additionalData: {
          ...path.additionalData,
          tags: ["get", "post", "put", "patch", "delete"]
            .reduce(
              (item, method) => [
                ...item,
                ...(path.additionalData[method]?.tags?.filter?.(
                  (tag) => !item.includes(tag)
                ) || [])
              ],
              []
            )
            .filter(Boolean)
        }
      }))
      .forEach((path) => {
        path.additionalData.tags.forEach((tag) => {
          if (tags[tag] && !tags[tag].some((p) => p.path === path.path)) {
            tags[tag] = [...tags[tag], path];
          } else if (!tags[tag]) {
            tags[tag] = [path];
          }
        });
      });

  return tags;
};

exports.sourceSnippets = (actions, createNodeId, createContentDigest) => {
  actions.createNode({
    res: phpSnippet,
    id: createNodeId(`PHP-SNIPPET`),
    internal: {
      type: "php",
      contentDigest: createContentDigest("php-snippet")
    }
  });
  actions.createNode({
    res: javaSnippet,
    id: createNodeId(`JAVA-SNIPPET`),
    internal: {
      type: "java",
      contentDigest: createContentDigest("java-snippet")
    }
  });
  actions.createNode({
    res: nodeSnippet,
    id: createNodeId(`NODE-SNIPPET`),
    internal: {
      type: "node",
      contentDigest: createContentDigest("node-snippet")
    }
  });
  actions.createNode({
    res: pythonSnippet,
    id: createNodeId(`PYTHON-SNIPPET`),
    internal: {
      type: "python",
      contentDigest: createContentDigest("python-snippet")
    }
  });
  actions.createNode({
    res: rubySnippet,
    id: createNodeId(`RUBY-SNIPPET`),
    internal: {
      type: "ruby",
      contentDigest: createContentDigest("ruby-snippet")
    }
  });
  actions.createNode({
    res: windevSnippet,
    id: createNodeId(`WINDEV-SNIPPET`),
    internal: {
      type: "windev",
      contentDigest: createContentDigest("windev-snippet")
    }
  });
  actions.createNode({
    res: csharpSnippet,
    id: createNodeId(`CSHARP-SNIPPET`),
    internal: {
      type: "csharp",
      contentDigest: createContentDigest("csharp-snippet")
    }
  });
  actions.createNode({
    res: scalaSnippet,
    id: createNodeId(`SCALA-SNIPPET`),
    internal: {
      type: "scala",
      contentDigest: createContentDigest("scala-snippet")
    }
  });
  actions.createNode({
    res: cppSnippet,
    id: createNodeId(`CPP-SNIPPET`),
    internal: {
      type: "cpp",
      contentDigest: createContentDigest("cpp-snippet")
    }
  });
};

exports.getName = (name) =>
  name
    ? name
        .toLocaleLowerCase()
        .replace(/[{}]|(api)|(rest)/g, "")
        .trim()
    : "";

exports.apiUrls = [
  "config/rest-sms-openapi/rest-sms.yml",
  //"https://api.swaggerhub.com/apis/smsmode_dev/TTS_REST_API/v1.0?resolved=true",
  "config/rest-commons-openapi/rest-commons.yml"
];
