import React, { useContext } from "react";
import { Location } from "@gatsbyjs/reach-router";
import styled from "styled-components";
import { Table } from "antd";
import { Context } from "../../../components/Layout/index";

import "antd/lib/table/style/index.css";

import "../../../themes/css/index.css";

const dataSource = [
  {
    key: 0,
    name: "messagePartCount",
    render: (html) => {
      return <Container dangerouslySetInnerHTML={{ __html: html }} />;
    },
    c1: 1,
    c2: 2,
    c3: 3,
    c4: 4,
    c5: 5,
    c6: 6,
    c7: 7,
    c8: 8,
    c9: 9,
    c10: 10
  },
  {
    key: 2,
    name: "Max length (GSM7)",
    render: (html) => {
      return <Container dangerouslySetInnerHTML={{ __html: html }} />;
    },
    c1: 160,
    c2: 306,
    c3: 459,
    c4: 612,
    c5: 765,
    c6: 918,
    c7: 1071,
    c8: 1224,
    c9: 1377,
    c10: 1530
  },
  {
    key: 3,
    name: "Max length (UNICODE)",
    render: (html) => {
      return <Container dangerouslySetInnerHTML={{ __html: html }} />;
    },
    c1: 70,
    c2: 134,
    c3: 201,
    c4: 268,
    c5: 335,
    c6: 402,
    c7: 469,
    c8: 536,
    c9: 603,
    c10: 670
  }
];

const columns = [
  {
    dataIndex: "name"
  },
  {
    dataIndex: "c1"
  },
  {
    dataIndex: "c2"
  },
  {
    dataIndex: "c3"
  },
  {
    dataIndex: "c4"
  },
  {
    dataIndex: "c5"
  },
  {
    dataIndex: "c6"
  },
  {
    dataIndex: "c7"
  },
  {
    dataIndex: "c8"
  },
  {
    dataIndex: "c9"
  },
  {
    dataIndex: "c10"
  }
];

const MsgCountTable = () => {
  const { width } = useContext(Context) || {};

  return (
    <Wrapper id="message_text_size" width={width}>
      <p>
        The Message resource is composed of concatenated SMS defined according
        to the encoding, the price of sending the message is calculated on the
        value of messagePartCount:
      </p>
      <Table columns={columns} dataSource={dataSource} pagination={false} />
    </Wrapper>
  );
};

const Container = styled.div``;

const Wrapper = styled.div`
  margin: 60px 0px 20px;
  width: 100%;
  max-width: 800px;
  .ant-table-thead > tr > th {
    display: none;
  }

  & > p {
    font-size: 14px;
    border-bottom: 1px solid #4444445d;
    padding-bottom: 20px;
    line-height: 25px;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border-bottom: ${({ theme }) => theme.border} !important;
  }

  .ant-table-cell,
  .ant-table {
    color: ${({ theme }) => theme.text} !important;
  }

  .ant-table-content {
    overflow-x: auto !important;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 8px 8px;
  }

  @media (max-width: 1360px) {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
      padding: 2px 2px;
    }
  }
`;

//eslint-disable-next-line
export default (props) => (
  <Location>
    {(locationProps) => <MsgCountTable {...locationProps} {...props} />}
  </Location>
);
