import styled from "styled-components";


export const ButtonStyle = styled.button`
  min-width: 10%;
  border-radius: 3px;
  border: none;
  outline: none;
  font-weight: 600;
  border: none;
  transition: 0.275s ease;
  margin: 0 15px 0 10px;
  color: ${({ isAuth }) => (!isAuth ? "rgb(253, 101, 101);" : "#52b474")} ;
  text-align: right;
  cursor: ${({ isAuth }) => (!isAuth ? "not-allowed" : "pointer")};
  &:hover {
    /* background-color: ${({ disable }) => (disable ? "transparent" : "#fff")}; */
    /* color: ${({ disable }) => (disable ? "#fff" : "rgb(20,45,71)")}; */
  }
`;


