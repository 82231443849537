import React from "react";
import Layout from "../src/components/Layout";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "../src/themes/global-style";
import { useDarkMode } from "../src/themes/useDarkMode";
import { lightTheme, darkTheme } from "../src/themes/theme";

const Provider = ({ children }) => {
  const [currentTheme, toggleTheme, componentMounted] = useDarkMode();
  const themeMode = currentTheme === "light" ? lightTheme : darkTheme;

  if (!componentMounted) {
    return <div />;
  }

  return (
    <ThemeProvider theme={themeMode}>
      <GlobalStyle />
      <Layout currentTheme={currentTheme} toggleTheme={toggleTheme}>
        {children}
      </Layout>
    </ThemeProvider>
  );
};

const wrapperProvider = ({ element }) => <Provider>{element}</Provider>;

export default wrapperProvider;
