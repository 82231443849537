import { useEffect } from "react";

export const useOnClickOutside = (ref, buttonRef, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (
        !buttonRef.current ||
        buttonRef.current.contains(event.target) ||
        !ref.current ||
        ref.current.contains(event.target)
      ) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
    // eslint-disabled-next-line
  }, [ref, handler, buttonRef]);
};
