import styled from "styled-components";

export const BoxType = styled.div`
  min-width: ${({ small }) => (small ? "35px" : "45px")};
  max-height: ${({ small }) => (small ? "16px" : "20px")};
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ color }) => color};
  border-radius: 5px;
  color: #fff;
  font-size: ${({ small }) => (small ? "10px" : "12px")};
  font-weight: 300;
`;
