export const lightTheme = {
  second: "#fafafa",
  body: "#edeeef",
  text: "#444",
  border: "1px solid #4444445d",
  borderColor: "#4444445d",
  breakpoints: {
    small: "only screen and (min-width: 1030px)",
    medium: "only screen and (min-width: 1441px)",
    large: "only screen and (min-width: 1550px)",
    smallHeight: "(max-height: 900px)",
    mediumHeight: "(max-height: 950px)",
  },
  error: '#fd6565',
  boxShadow: '1px 1px 10px 0px #7a7a7a',
};

export const darkTheme = {
  second: "#0b3733",
  body: "#082429",
  text: "#BDBDBD",
  border: "1px solid #BDBDBD5d",
  borderColor: "#BDBDBD5d",
  breakpoints: {
    small: "only screen and (min-width: 1030px)",
    medium: "only screen and (min-width: 1441px)",
    large: "only screen and (min-width: 1550px)",
    smallHeight: "(max-height: 900px)",
    mediumHeight: "(max-height: 950px)",
  },
  error: '#fd6565',
  boxShadow: '1px 1px 10px 0px #000000',
};
