exports.csharp = `using System;
using System.IO;
using System.Net.Http;
using System.Net.Http.Headers;
using System.Threading.Tasks;
using System.Text;
using System.Web;
 
/**
*    Function parameters:
*
*    - accessToken (required)
*    - message (required)
*    - destinataires (required): Receivers separated by a comma
*    - emetteur (optional): Allows to deal with the sms sender
*    - optionStop (optional): Deal with the STOP sms when marketing send (cf. API HTTP documentation)
*    - batchFilePath (required for batch mode): The path of CSV file for sms in Batch Mode
*/
 
namespace ExempleClientHttpApi
{
 public class ExempleClientHttpApi {
 
     private static string URL = "https://api.smsmode.com/http/1.6/";
     private static string PATH_SEND_SMS = "sendSMS.do";
     private static string PATH_SEND_SMS_BATCH = "sendSMSBatch.do";
     private static string ERROR_API = "Error during API call\n";
     private static string ERROR_FILE = "The specified file does not exist\n";
     private static readonly HttpClient _httpClient= new HttpClient();
  
     public ExempleClientHttpApi()
     {
         Encoding.RegisterProvider(CodePagesEncodingProvider.Instance);
     }
 
     // send SMS with GET method 
     public string sendSmsGet(string accessToken, string message, string destinataires, string emetteur, string optionStop) { 
 
         string urlEncodedMessage = HttpUtility.UrlEncode(message, Encoding.GetEncoding("ISO-8859-15"));
         string finalUrl = URL + PATH_SEND_SMS + "?accessToken=" + accessToken + "&numero=" + destinataires + "&message=" + urlEncodedMessage + \n"&emetteur=" + emetteur + "&stop=" + optionStop;
  
         return Get(finalUrl).Result;
     }
 
     public static async Task<string> Get(string url){
 
         try {
             var response = await _httpClient.GetAsync(url);
             string result = await response.Content.ReadAsStringAsync();
             return result;
         } catch (Exception) {
             return ERROR_API;
         }
     }
 
     // send SMS with POST method 
     public string sendSmsPost(string accessToken, string message, string destinataires, string emetteur, string optionStop) {
 
         string finalUrl = URL + PATH_SEND_SMS;
 
         StringBuilder sb = new StringBuilder();
         sb.Append("accessToken=" + accessToken);
         sb.Append("&numero=" + destinataires);
         sb.Append("&emetteur=" + emetteur);
         sb.Append("&message=" + HttpUtility.UrlEncode(message, Encoding.GetEncoding("ISO-8859-15")));
         sb.Append("&stop=" + optionStop);
 
         return Post(finalUrl ,Encoding.Default.GetBytes(sb.ToString())).Result;
     }
 
     public static async Task<string> Post(string url, byte[] data){
 
         ByteArrayContent byteContent = new ByteArrayContent(data);
         byteContent.Headers.ContentType = new MediaTypeHeaderValue("application/x-www-form-urlencoded");
         try {
             var response = await _httpClient.PostAsync(url, byteContent);
             string result = await response.Content.ReadAsStringAsync();
             return result;
         } catch (Exception) {
             return ERROR_API;
         }
     }
 
     // send SMS with POST method (batch)
     public string sendSmsBatch(string accessToken, string batchFilePath, string optionStop) {
 
         if (!File.Exists(batchFilePath)) {
             return ERROR_FILE;
         }
         string finalUrl = URL + PATH_SEND_SMS_BATCH + "?accessToken=" + accessToken + "&stop=" + optionStop;
         return PostBatch(finalUrl , File.ReadAllBytes(batchFilePath), batchFilePath).Result;
     }
 
     public static async Task<string> PostBatch(string url, byte[] data, string batchFilePath) {
 
         var requestContent = new MultipartFormDataContent();
         ByteArrayContent byteContent = new ByteArrayContent(data);
         byteContent.Headers.ContentType = MediaTypeHeaderValue.Parse("text/csv");
         requestContent.Add(byteContent, "file", batchFilePath);
         try {
             var response = await _httpClient.PostAsync(url, requestContent);
             string result = await response.Content.ReadAsStringAsync();
             return result;
         } catch (Exception) {
             return ERROR_API;
         }
     }
 }
}`;
