import React, { useEffect, useState, useContext } from "react";

import { useStaticQuery, graphql, navigate } from "gatsby";
import styled, { keyframes } from "styled-components";
import { Location } from "@gatsbyjs/reach-router";
// import { translate as t } from "../../helpers/i18n";
import { BoxType } from "../../components/PageElements";
import {
  scrollToSection,
  getName,
  getOperationColor,
  handleTypeDisplay
} from "../../helpers/utils";
import { getPathsForTags } from "../../../gatsby/utils";
import BurgerMenu from "../BurgerMenu";
import { Context } from "../Layout/index";

const SideBarWrapper = ({ children }) => {
  const [displayBurger, setDisplayBurger] = useState(false);
  const { width } = useContext(Context) || {};

  return (
    <>
      {width > 1100 ? (
        <Container
          width={width}
          offsetTop={document.getElementById("layout_content")?.offsetTop ?? 0}
          data-testid="Sidebar-Container"
        >
          {children}
        </Container>
      ) : (
        /* Responsive menu */
        <BurgerMenu
          color="#52b474"
          onClick={() => setDisplayBurger(!displayBurger)}
          display={displayBurger}
          setDisplay={setDisplayBurger}
        >
          {children}
          <LinksWrapper>
            <a
              href="https://ticket.smsmode.com/tickets-view"
              target="_blank"
              rel="noopener noreferrer"
            >
              Support
            </a>
            <a
              href="https://ui.smsmode.com/login"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sign in
            </a>
            {/* <SelectLanguage /> */}
          </LinksWrapper>
        </BurgerMenu>
      )}
    </>
  );
};

const SideBar = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        edges {
          node {
            frontmatter {
              path
              title1
            }
          }
        }
      }
      allApi {
        nodes {
          name
          tags {
            name
          }
          paths {
            path
            additionalData {
              summary
              version
              post {
                tags
                summary
              }
              get {
                tags
                summary
              }
              patch {
                tags
                summary
              }
              delete {
                tags
                summary
              }
            }
          }
        }
      }
    }
  `);

  const [sections, setSections] = useState();
  const [apiName] = useState(getName(location.pathname.split("/")[1]) || "");
  const [apiVersion] = useState(getName(location.pathname.split("/")[2]) || "");
  const [current] = useState(
    data?.allApi?.nodes.filter((node) => getName(node.name) === apiName) || []
  );

  useEffect(() => {
    setSections(getPathsForTags(current && current[0]));
  }, [apiName, current]);

  const handleLowerCase = (str) => {
    return str.toLocaleLowerCase();
  };

  const [display, setDisplay] = useState(
    location.pathname.split("/")[location.pathname.split("/").length - 1]
  );

  useEffect(() => {
    if (
      display ===
      location.pathname.split("/")[location.pathname.split("/").length - 1]
    )
      setDisplay(
        location.pathname.split("/")[location.pathname.split("/").length - 1]
      );
    else setDisplay("");
    //eslint-disable-next-line
  }, [location.pathname]);

  const getSummaryForPath = (section) => {
    const methods =
      section &&
      Object.entries(section.additionalData).filter(
        ([key, value]) =>
          key !== "tags" &&
          key !== "summary" &&
          key !== "version" &&
          value?.summary
      );
    return methods;
  };

  const { width } = useContext(Context) || {};

  return (
    <SideBarWrapper>
      {/* Api home page */}
      {data &&
        data.allMarkdownRemark.edges
          .filter(
            (node) =>
              node.node.frontmatter.title1 && node.node.frontmatter.title1
          )
          .filter(
            (node) =>
              getName(node.node.frontmatter.title1) ===
              getName(current?.[0]?.name)
          )
          .map((node) => (
            <StyledLink
              width={width}
              key={node.node.frontmatter.path}
              onClick={() => {
                setDisplay("");
                navigate(`/${node.node.frontmatter.path}`);
              }}
              data-testid="Sidebar-Link-Home"
            >
              {node.node.frontmatter.title1}
            </StyledLink>
          ))}
      {/* Api sections */}
      {sections &&
        Object.entries(sections).map(([sectionName, section]) => (
          <div key={sectionName}>
            <StyledLink
              width={width}
              onClick={(e) => {
                setDisplay(
                  e.target.innerText.toLocaleLowerCase().replace(" ", "-")
                );
                navigate(
                  `/${apiName}/${apiVersion}/${
                    handleLowerCase(sectionName.replace(" ", "-")) || "/"
                  }`
                );
                // document
                //   .getElementById("layout_content")
                //   .scrollTo({ top: 0, behavior: "smooth" });
              }}
              // to={`/${apiName}/${apiVersion}/${
              //   handleLowerCase(sectionName.replace(" ", "-")) || "/"
              // }`}
            >
              {sectionName}
            </StyledLink>
            {display === sectionName.toLocaleLowerCase().replace(" ", "-") &&
              section.map((item) =>
                getSummaryForPath(item).map((s) => (
                  <SubSection
                    key={s[0]}
                    onClick={() =>
                      scrollToSection(
                        `${s[0]}_${item.additionalData[s[0]].summary
                          ?.replace(" ", "_")
                          .toLocaleLowerCase()}`,
                        -20 -
                          (document.getElementById("layout_content")
                            ?.offsetTop ?? 0)
                      )
                    }
                  >
                    <BoxType small color={getOperationColor(s[0], item.path)}>
                      {handleTypeDisplay(s[0], item.path)}
                    </BoxType>
                    {s[1].summary}
                  </SubSection>
                ))
              )}
          </div>
        ))}
      {/* Api errors */}
      <StyledLink
        width={width}
        onClick={() => {
          setDisplay("");
          navigate(`/${apiName}/errors`);
        }}
        data-testid="Sidebar-Link-Error"
      >
        Errors
      </StyledLink>
    </SideBarWrapper>
  );
};

const fade = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const SubSection = styled.div`
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 5px;
  animation: ${fade} 0.3s;
  border-radius: 3px;
  &:hover {
    background-color: #52b4743d;
    transition: 0.3s;
  }
  font-size: 12px;
`;

const Container = styled.div`
  grid-area: sidebar;
  width: 210px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: auto;
  border-right: ${({ theme, width }) => (width > 1100 ? theme.border : "")};
  position: sticky;
  top: ${({ offsetTop }) => `${offsetTop}px`};
  height: calc(100vh - ${({ offsetTop }) => `${offsetTop}px`});
  // @media (max-height: 980px) {
  //   height: 800px;
  // }
  // @media (max-height: 950px) {
  //   height: 740px;
  // }
  // @media (max-width: 1435px) and (max-height: 764px) {
  //   height: 600px;
  // }
`;

const StyledLink = styled.div`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.text};
  display: flex;
  flex-direction: column;
  padding: 8px 5px;
  margin: 2px 0;
  border-radius: 3px;
  font-size: 16px;
  &:hover {
    background-color: #52b4743d;
    transition: 0.3s;
  }
`;
// background-color: ${({ selected }) => selected === true && "#52b4743d"};

const LinksWrapper = styled.div`
  display: flex;
  font-weight: 400;
  height: 100%;
  & > *:hover {
    background-color: #52b4743d;
    transition: 0.3s;
  }
  & > * {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${({ theme }) => theme.text};
    height: 100%;
    font-size: 14px;
    padding: 8px 5px;
    margin: 2px 0;
    border-radius: 3px;
  }
  & > a:last-child {
    background-color: #52b474;
    color: #fff;
    height: 35px;
    padding: 0 15px;
    border-radius: 5px;
    transition: ease 0.275s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & > a:last-child:hover {
    transform: translateY(-0.1em);
    box-shadow: 0 15px 15px -15px rgba(0, 0, 0, 0.5);
  }
`;

//eslint-disable-next-line
export default (props) => (
  <Location>
    {(locationProps) => <SideBar {...locationProps} {...props} />}
  </Location>
);
