import React, { createContext, useState, useEffect } from "react";
import styled from "styled-components";
import SideBar from "../Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import { Location } from "@gatsbyjs/reach-router";
import "../../themes/css/index.css";
import "../../themes/css/tarteaucitron_smsmode.css";
import { X_API_KEY_KEY, isApiKeyValid } from "../Auth";

export const Context = createContext();

const Layout = ({ currentTheme, toggleTheme, children, location }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [isAuth, setIsAuth] = useState();
  const [apiKey, setApiKey] = useState(
    sessionStorage.getItem(X_API_KEY_KEY) ?? ""
  );

  const handleResize = () => {
    if (typeof window !== "undefined") {
      setWidth(() => window.innerWidth);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  useEffect(() => {
    const handleKeyChange = async () => {
      const validKey = await isApiKeyValid(apiKey);
      setIsAuth(validKey);
      sessionStorage.setItem(X_API_KEY_KEY, apiKey);
    };
    handleKeyChange();
  }, [apiKey, setIsAuth]);

  const path = location.pathname
    .split("/")
    .filter(Boolean)
    .find((item) => item === "commons" || item === "sms" || item === "tts");

  const isWellKnown = location.pathname
    .split("/")
    .filter(Boolean)
    .find((item) => item === ".well-known");

  const removePadding =
    location.pathname === "/" ||
    location.pathname.includes("http-api") ||
    location.pathname.includes("mail2sms");

  return (
    <LayoutContainer location={location} isWellKnown={isWellKnown}>
      <Context.Provider
        value={{
          currentTheme,
          width,
          isAuth,
          setIsAuth,
          apiKey,
          setApiKey
        }}
      >
        {!isWellKnown && (
          <Header width={width} toggleTheme={toggleTheme} location={location} />
        )}

        {path && <SideBar />}
        <Content id="layout_content" removePadding={removePadding}>
          {children}
        </Content>
        {!isWellKnown && <Footer />}
      </Context.Provider>
    </LayoutContainer>
  );
};

const LayoutContainer = styled.div`
  position: relative;
  min-height: 100vh;
  display: grid;
  grid-template:
    "header header" max-content
    "sidebar content" auto
    "footer footer" max-content
    / auto 1fr;
`;

const Content = styled.div`
  grid-area: content;
  padding: ${({ removePadding }) => (removePadding ? "0px" : "20px")};
`;

//eslint-disable-next-line
export default (props) => (
  <Location>
    {(locationProps) => <Layout {...locationProps} {...props} />}
  </Location>
);
