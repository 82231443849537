/* eslint-disable */
exports.ruby = `require 'net/http'
require 'uri'
require 'json'
 
 
#    Function parameters:
#
#    - accessToken (required)
#    - message (required)
#    - destinataires (required): Receivers separated by a comma
#    - emetteur (optional): Allows to deal with the sms sender
#    - optionStop (optional): Deal with the STOP sms when marketing send (cf. API HTTP documentation)
#    - batchFilePath (required for batch mode): The path of CSV file for sms in Batch Mode
 
class ExempleClientHttpApi
 
 ERROR_FILE = "The specified file does not exist";
 URL = "https://api.smsmode.com/http/1.6/"
 PATH_SEND_SMS = "sendSMS.do"
 PATH_SEND_SMS_BATCH = "sendSMSBatch.do"
 
 def send_sms_get(access_token, message, destinataires, emetteur, option_stop)
 
     header = {'Content-Type' => 'plain/text; charset=ISO-8859-15'}
     params = { :accessToken => access_token, :message => message.encode(Encoding::ISO_8859_15), :numero => destinataires, \n:emetteur => emetteur, :stop => option_stop}
 
     uri = URI.parse(URL + PATH_SEND_SMS);
     uri.query = URI.encode_www_form(params)
 
     http = Net::HTTP.new(uri.host,uri.port)
     http.use_ssl = true
     request = Net::HTTP::Get.new(uri.request_uri,header)
 
     res = http.request(request)
     return res.body
 end
 
 # send SMS with POST method
 def send_sms_post(access_token, message, destinataires, emetteur, option_stop)
 
     header = {'Content-Type' => 'application/x-www-form-urlencoded; charset=ISO-8859-15'}
     params = { :accessToken => access_token, :message => message.encode(Encoding::ISO_8859_15), :numero => destinataires, \n:emetteur => emetteur, :stop => option_stop}
     uri = URI.parse(URL + PATH_SEND_SMS);
 
     http = Net::HTTP.new(uri.host,uri.port)
     http.use_ssl = true
     request = Net::HTTP::Post.new(uri.request_uri,header)
     request.body = URI.encode_www_form(params)
 
     res = http.request(request)
     return res.body
 end
 
 # send SMS with POST method (Batch)
 def send_sms_batch(access_token, batch_file_path, option_stop)
 
     if !File.file?(batch_file_path)
         return ERROR_FILE
     end
 
     boundary = "AaB03x"
     header = {'Content-Type' => "multipart/form-data, boundary=#{boundary}"}
     uri = URI.parse(URL + PATH_SEND_SMS_BATCH + '?accessToken=' + access_token + "&stop=" + option_stop);
 
     post_body = []
     post_body << "--#{boundary}\\r\\n"
     post_body << "Content-Disposition: form-data; name="datafile"; filename="#{File.basename(batch_file_path)}"\\r\\n"
     post_body << "\\r\\n"
     post_body << File.read(batch_file_path)
     post_body << "\\r\\n--#{boundary}--\\r\\n"
 
     http = Net::HTTP.new(uri.host,uri.port)
     http.use_ssl = true
     request = Net::HTTP::Post.new(uri.request_uri, header)
     request.body = post_body.join
 
     res = http.request(request)
     return res.body
 end
 
end`;
