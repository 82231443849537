import React, { useRef, useEffect } from "react";
import {
  ButtonWrapper,
  BurgerIcon,
  BurgerDropDown,
  CrossIcon,
} from "./BurgerMenu.module.css";
import { bool } from "prop-types";
import { classNames } from "../../helpers/classNames";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { Location } from "@gatsbyjs/reach-router";
import styled from "styled-components";

const BurgerMenu = ({
  color,
  display,
  setDisplay,
  children,
  location,
  pdf,
  mail,
  navigate,
  ...props
}) => {
  const burgerRef = useRef();
  const buttonRef = useRef();

  useOnClickOutside(burgerRef, buttonRef, () => setDisplay(false));

  useEffect(() => {
    setDisplay(false);
    // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <Wrapper data-testid="BurgerMenu-Wrapper" pdf={pdf} mail={mail}>
      <button
        {...props}
        ref={buttonRef}
        type="button"
        className={ButtonWrapper}
        data-testid="BurgerMenu-Button"
      >
        <div
          style={{
            "--bg-color": `${color}`,
          }}
          className={classNames([BurgerIcon, display && CrossIcon])}
        />
      </button>
      {display && (
        <DropDown
          data-testid="BurgerMenu-DropDown"
          ref={burgerRef}
          style={{
            "--bg-color": `${({ theme }) => theme.text}`,
          }}
          className={BurgerDropDown}
        >
          {children}
        </DropDown>
      )}
    </Wrapper>
  );
};

const DropDown = styled.div`
  background-color: ${({ theme }) => `${theme.body} !important`};
  & > a,
  & > div > * {
    color: ${({ theme }) => `${theme.text}`};
  }
`;

const Wrapper = styled.div`
  display: block;
  z-index: 99;

  @media (min-width: ${({ pdf, mail }) =>
      pdf && !mail ? "900px" : "1100px"}) {
    display: none;
  }
`;

BurgerMenu.propTypes = {
  display: bool.isRequired,
};

//eslint-disable-next-line
export default (props) => (
  <Location>
    {(locationProps) => <BurgerMenu {...locationProps} {...props} />}
  </Location>
);
