import React, { useContext } from "react";
import styled from "styled-components";
import { Location } from "@gatsbyjs/reach-router";
import { Context } from "../../components/Layout";

import { Sup } from "../../components/PageElements";

import FrenchTech from "../../images/frenchtech.png";
import GsmaLight from "../../images/logo-gsma-light.png";
import GsmaDark from "../../images/logo-gsma-dark.png";
// import AllianceLight from "../../images/logo-alliance-light.png";
// import AllianceDark from "../../images/logo-alliance-dark.png";
// import Iso from "../../images/iso-27001.png";
// import AllianceDark from "../../images/iso-27001.png";

import Iso from "../../images/Afaq_27001.png";
import Iso2 from "../../images/Afaq_27701_outline.png";


import Fw500Dark from "../../images/FW500-dark-mode.png";
import Fw500Light from "../../images/FW500-light-mode.png";

import Twitter from "../../images/twitter.png";
import Linkedin from "../../images/linkedin.png";
import Youtube from "../../images/youtube.png";

import Logo from "../../svg/smsmode-icon.svg";

import Round from './Round'

const Footer = ({ location }) => {
  const { currentTheme, width } = useContext(Context) || {};
  const isCallback = location.pathname.split("/").includes("callback");

  return (
    <Wrapper isCallback={isCallback}>
      {location.pathname === "/" && width > 850 ? (
        <>
          <ColoredContainer>
            <Container>
              <Background>
                <Images>
                  <a
                    href="https://www.gsma.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={currentTheme === "light" ? GsmaLight : GsmaDark}
                      alt="gsma"
                    />
                  </a>
                  <a
                    href="https://lafrenchtech-aixmarseille.fr/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      style={{ transform: "scale(1.5)" }}
                      src={FrenchTech}
                      alt="FrenchTech"
                    />
                  </a>
                  <a
                    href="https://www.frenchweb.fr/fw500"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={currentTheme === "light" ? Fw500Dark : Fw500Light}
                      alt="Fw500"
                    />
                  </a>
                  <a
                    href="https://www.smsmode.com/securite-donnees-smsmode/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      style={{ transform: "scale(1.6)" }}
                      src={Iso}
                      alt="iso-27001"
                    />
                  </a>
                  <a
                    href="https://fr.wikipedia.org/wiki/R%C3%A8glement_g%C3%A9n%C3%A9ral_sur_la_protection_des_donn%C3%A9es"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      style={{ transform: "scale(1.6)" }}
                      src={Iso2}
                      alt="Fw500"
                    />
                  </a>
                </Images>
              </Background>
            </Container>
          </ColoredContainer>
          <Container>
            <div>
              <LogoWrapper>
                <Logo />
              </LogoWrapper>
              <Column>
                <h3>Follow us on :</h3>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "20px"
                  }}
                >
                  <a
                    href="https://www.linkedin.com/company/calade-technologies"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={Linkedin}
                      alt="linkedin"
                      rel="noopener noreferrer"
                    />
                  </a>

                  <a
                    href="https://www.youtube.com/channel/UCfIh0nCZQXWeaGjXGgO8HxA"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Twitter} alt="twitter" />
                  </a>
                  <a
                    href="https://twitter.com/smsmode"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Youtube} alt="youtube" />
                  </a>
                </div>
              </Column>
              <Column>
                <h3>Need more info ?</h3>
                <a
                  href="https://www.smsmode.com/en/contacts/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Do not hesitate to contact us.
                </a>
              </Column>
            </div>
          </Container>
        </>
      ) : (
        <>
          <RoundLeft side="left" />
          <RoundRight side="right" />
        </>
      )}
      <Bottom>
        <p>
          2022 smsmode<Sup>©</Sup>
        </p>
        <span>•</span>
        <p>All rights reserved</p>
        <span>•</span>
        <Link
          href="https://www.smsmode.com/en/mentions-legales/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Legal Notice
        </Link>
        <span>•</span>
        <Link
          href="https://www.smsmode.com/en/conditions-generales-dutilisation/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms and Conditions
        </Link>
        <span>•</span>
        <Link
          href="https://www.smsmode.com/politique-de-confidentialite/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </Link>
        <span>•</span>
        <Link href="/#tarteaucitron">Cookie management</Link>
      </Bottom>
    </Wrapper>
  );
};

const Link = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.text};
  font-size: 13px;
  color: #52b474;
  &:hover {
    text-decoration: underline;
  }
`;

const Images = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  & > a {
    transition: 0.275s ease;
    font-size: 50px;
    display: flex;
    border-radius: 50%;
    height: 130px;
    min-width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.second};
    &:hover {
      filter: none;
    }
  }
  & > a > img {
    border-radius: 5px;
    height: 50px;
    max-width: 110px;
    object-fit: unset;
  }
`;

const Bottom = styled.div`
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #52b474;
  flex-wrap: wrap;
  & > span {
    margin: 0 25px;
    @media (max-width: 850px) {
      display: none;
    }
  }
  @media (max-width: 850px) {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 155px;
    margin: 15px 0;
  }
  & > * {
    @media (max-width: 850px) {
      margin: 0 15px;
    }
  }
  & > p {
    text-decoration: none;
    color: #52b474;
    font-size: 13px;
  }
`;

const Wrapper = styled.footer`
  grid-area: footer;
  display: flex;
  flex-direction: column;
  width: 100%;
  // margin-top: ${({ isCallback }) => (isCallback ? "0" : "60px")};
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.second};
  position: relative;
`;

const RoundLeft = styled(Round)`
  position: absolute;
  height: 100%;
  top: -100%;
  left: 0;
  @media (max-width: 850px) {
    display: none;
  }
`

const RoundRight = styled(Round)`
  position: absolute;
  height: 100%;
  top: -100%;
  right: 0;
  @media (max-width: 850px) {
    display: none;
  }
`

const Container = styled.div`
  background-color: transparent;
  width: 100%;
  height: 250px;
  max-width: 1232px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 10px;
  & > div {
    display: flex;
    justify-content: space-between;
  }
`;

const ColoredContainer = styled.div`
  background-color: ${({ theme }) => theme.body};
  width: 100%;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 60px;
  border-bottom-right-radius: 60px;
`;

const Background = styled.div`
  width: 100%;
`;

const LogoWrapper = styled.div`
  & > svg {
    height: 50px;
    width: 50px;
  }
`;

const Column = styled.div`
  min-width: 150px;
  display: flex;
  flex-direction: column;
  & > h3 {
    font-weight: bold;
    color: #52b474;
  }
  & > div > a {
    cursor: pointer;
    transition: 0.275s ease;
    -webkit-filter: opacity(0.5) grayscale(1)
      drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.25));
    filter: opacity(0.5) grayscale(1);
    border-radius: 50%;

    &:hover {
      filter: none;
      box-shadow: 0 5px 20px rgb(0 0 0 / 10%);
    }
  }
  & > div > a > img {
    background-color: #fff;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    object-fit: cover;
  }
  & > a {
    margin-top: 20px;
    text-decoration: none;
    color: ${({ theme }) => theme.text};
    &:hover {
      text-decoration: underline;
    }
  }
`;

//eslint-disable-next-line
export default (props) => (
  <Location>
    {(locationProps) => <Footer {...locationProps} {...props} />}
  </Location>
);
