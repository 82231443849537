import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

export const GlobalStyle = createGlobalStyle`
  ${reset}
  *, *:before, *:after {
      --background-second: ${({ theme }) => theme.second};
      --background-body: ${({ theme }) => theme.body};
      --background-text: ${({ theme }) => theme.text};
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }
    body, html {
      height: 100%;
      font-family: 'Hanken Grotesk', sans-serif;
      scroll-behavior: smooth;
      transition: all 0.25s ease;
      color: ${({ theme }) => theme.text};
      background-color: ${({ theme }) => theme.body};
    }
    * {
      font-family: 'Hanken Grotesk', sans-serif;
    } 
    *::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    *::-webkit-scrollbar-thumb {
    background: #52B474;
    border-radius: 5px;
    }
    sup {
      vertical-align: super;
      font-size: 60%;
    }
    img {
      pointer-events: none;
    }
    #ValidatorsWrapper-errorMessage {
      display: none;
    }
    *::selection {
      background: #52b4743d;
    }
`;
