import React, {  useContext } from "react";

import styled from "styled-components";

import Padlock from "../../svg/padlock.svg";
import Shield from "../../svg/secure-shield.svg";
import { Context } from "../Layout/index";

// import { useStaticQuery, graphql } from "gatsby";

export const X_API_KEY_KEY = "X-Api-Key";

export const isApiKeyValid = async (apiKey) => {
  if (apiKey?.length === 32) {
    try {
      const response = await fetch(
        `${process.env.GATSBY_API_ROOT}/sms/v1/messages`,
        {
          method: "OPTIONS",
          headers: {
            Accept: "application/json",
            [X_API_KEY_KEY]: apiKey
          }
        }
      );
      if (response.status === 200) {
        return true;
      }
    } catch {
      return false;
    }
  }
  return false;
}

const Auth = () => {
  const { isAuth, apiKey, setApiKey } = useContext(Context) || {};

  return (
    <Wrapper data-testid="Auth-Wrapper">
      <ApiKeyInput
        type="text"
        onChange={(e) => {
          if (e.target.value?.length <= 32) {
            setApiKey(e.target.value);
          }
        }}
        isAuth={isAuth}
        value={apiKey}
        placeholder={X_API_KEY_KEY}
        data-testid="Auth-ApiKeyInput"
        id="api_key_input"
        className="auth"
      />
      {isAuth ? (
        <SvgWrapper>
          <Shield data-testid="Auth-Shield" />
        </SvgWrapper>
      ) : (
        <SvgWrapper>
          <Padlock data-testid="Auth-Padlock" />
        </SvgWrapper>
      )}
    </Wrapper>
  );
};

const ApiKeyInput = styled.input`
  font-size: 14px;
  padding: 5px;
  background: ${(props) =>
    props.value ? (props.isAuth ? 'none' : props.theme.body) : '#fd65654d'};
  color: ${(props) =>
    props.value ? (props.isAuth ? 'inherit' : props.theme.text) : '#fd6565'};
  border: none;
  border-radius: 3px;
  outline: none;
  margin-right: 10px;
  width: ${(props) => (props.isAuth ? '0' : 'unset')};

  ::placeholder {
    color: ${({ theme }) => theme.text};
  }

  transition: width 0.5s ease-in-out, background 0.5s ease-in-out, color 0.5s ease-in-out;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  &:hover {
    ${ApiKeyInput} {
      width: unset;
      background: ${({ theme }) => theme.body};
    }
  }
`;

const SvgWrapper = styled.div`
margin-right: 10px;
`;

export default Auth;
